var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-save",attrs:{"title":"Order Result","ok-title":"Save","cancel-title":"Close","cancel-variant":"outline-secondary","no-close-on-backdrop":"","centered":"","size":"lg"},on:{"hidden":_vm.hiddenModal,"ok":_vm.handleOk}},[_c('validation-observer',{ref:"saveOrderResult"},[_c('b-form',{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.saveOrderResult($event)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('Result Title')}},[_c('validation-provider',{attrs:{"rules":"required","name":"Ad"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"resultTitle","placeholder":_vm.$t('Ad')},model:{value:(_vm.modalObject.item.resultTitle),callback:function ($$v) {_vm.$set(_vm.modalObject.item, "resultTitle", $$v)},expression:"modalObject.item.resultTitle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Result Status')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.orderResultStatusOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"orderStatus","placeholder":"Select Status"},model:{value:(_vm.resultStatus),callback:function ($$v) {_vm.resultStatus=$$v},expression:"resultStatus"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Region')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.presetRegionList,"reduce":function (val) { return val.id; },"clearable":false,"label":"title","input-id":"orderRegion","placeholder":"Select Region"},model:{value:(_vm.regionId),callback:function ($$v) {_vm.regionId=$$v},expression:"regionId"}})],1),_c('b-form-group',{attrs:{"label":"Result Desc"}},[_c('validation-provider',{attrs:{"name":"Result Desc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"Result Desc"},model:{value:(_vm.modalObject.item.resultDescription),callback:function ($$v) {_vm.$set(_vm.modalObject.item, "resultDescription", $$v)},expression:"modalObject.item.resultDescription"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"scaled Coordinate"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Coordinate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"source-input",attrs:{"id":"textarea-auto-height","placeholder":"Coordinate","rows":"4","no-auto-shrink":"","state":errors.length > 0 ? false:null},model:{value:(_vm.modalObject.item.targetCoordinate),callback:function ($$v) {_vm.$set(_vm.modalObject.item, "targetCoordinate", $$v)},expression:"modalObject.item.targetCoordinate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Unscaled Coordinate"}},[_c('validation-provider',{attrs:{"name":"UnScaled Coordinate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"source-input",attrs:{"id":"textarea-auto-height","placeholder":"Coordinate","rows":"4","no-auto-shrink":"","state":errors.length > 0 ? false:null},model:{value:(_vm.modalObject.item.targetUnScaledCoordinate),callback:function ($$v) {_vm.$set(_vm.modalObject.item, "targetUnScaledCoordinate", $$v)},expression:"modalObject.item.targetUnScaledCoordinate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"","label-for":"contact-options","label-class":"mb-1"}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-checkbox',{staticClass:"custom-control-primary",model:{value:(_vm.isChangeCoordinate),callback:function ($$v) {_vm.isChangeCoordinate=$$v},expression:"isChangeCoordinate"}},[_vm._v(" "+_vm._s(_vm.$t('Did the scaled coordinate change?'))+" ")])],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }