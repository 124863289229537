<template>
  <div>
    <b-modal
      ref="modal-save"
      title="Order Result"
      ok-title="Save"
      cancel-title="Close"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      centered
      @hidden="hiddenModal"
      @ok="handleOk"
      size="lg"
    >
      <validation-observer ref="saveOrderResult">
        <b-form
          ref="form"
          @submit.stop.prevent="saveOrderResult">
          <b-form-group
            :label="$t('Result Title')"
          >
           <validation-provider #default="{ errors }" rules="required" name="Ad">
            <b-form-input
              id="resultTitle"
              v-model="modalObject.item.resultTitle"
              :placeholder="$t('Ad')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('Result Status')"
          >
            <v-select
              v-model="resultStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="orderResultStatusOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="orderStatus"
              placeholder="Select Status"/>
          </b-form-group>
          <b-form-group
            :label="$t('Region')"
          >
            <v-select
              v-model="regionId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="presetRegionList"
              :reduce="(val) => val.id"
              :clearable="false"
              label="title"
              input-id="orderRegion"
              placeholder="Select Region"/>
          </b-form-group>
          <b-form-group
            label="Result Desc"
          >
           <validation-provider #default="{ errors }" name="Result Desc">
            <b-form-input
              v-model="modalObject.item.resultDescription"
              type="text"
              placeholder="Result Desc"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
          <b-form-group
            label="scaled Coordinate"
          >
           <validation-provider #default="{ errors }" rules="required" name="Coordinate">
            <b-form-textarea
              id="textarea-auto-height"
              v-model="modalObject.item.targetCoordinate"
              class="source-input"
              placeholder="Coordinate"
              rows="4"
              no-auto-shrink
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
          <b-form-group
            label="Unscaled Coordinate"
          >
           <validation-provider #default="{ errors }" name="UnScaled Coordinate">
            <b-form-textarea
              id="textarea-auto-height"
              v-model="modalObject.item.targetUnScaledCoordinate"
              class="source-input"
              placeholder="Coordinate"
              rows="4"
              no-auto-shrink
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
          <b-form-group
            label=""
            label-for="contact-options"
            label-class="mb-1">
            <div class="demo-inline-spacing">
              <b-form-checkbox
                v-model="isChangeCoordinate"
                class="custom-control-primary">
                {{ $t('Did the scaled coordinate change?')}}
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'
// Options
import StaticOptions from '@/common/options/StaticOptions';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      required,
      regionId: this.modalObject.item.presetRegionId,
      resultStatus: this.modalObject.item.orderResultStatus,
      orderResultStatusOptions: StaticOptions.orderResultStatusOptions,
      isChangeCoordinate: false,
    }
  },
  methods: {
    saveOrderResult() {
      this.$refs.saveOrderResult.validate().then(success => {
        if (success) {
          this.modalObject.item.orderResultStatus = this.resultStatus;
          this.modalObject.item.presetRegionId = this.regionId;
          const query = {
            orderResult: this.modalObject.item,
            isChangeCoordinate: this.isChangeCoordinate,
          };

          this.$store.dispatch('orderManagement/saveOrderResult', query, this)
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              })
              this.modalObject.onSuccess();
              this.hiddenModal();
            } else {
             AlertService.error(this, response.message)
            }
          });
        }
      })
    },

    initialModal() {
      this.$refs['modal-save'].show();
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.saveOrderResult()
    },
    hiddenModal() {
      this.$refs['modal-save'].hide()
      this.modalObject.showModal = false;
    },
  },
  computed: {
    presetRegionList() {
      return this.$store.state.presetRegionManagement.presetRegionList.filter(x => x.presetRegionType === 1);
    },
  },
  mounted() {
    this.initialModal();
  },
  created() {
    this.$store.dispatch('presetRegionManagement/fetchPresetRegionList', {});
  },
};
</script>
