<template>
  <div>
    <!-- Buttons -->
    <b-button-group>
      <b-button @click="fetchOrderRepor"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
      >
       <b-spinner
          v-if="$store.getters.getLoader"
          class="mr-1"
          small
          variant="light"
        />
        <feather-icon
          v-else
          icon="ListIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ $t('Fetch') }}</span>
      </b-button>
    </b-button-group>
    <!-- Filters -->
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="6" class="mb-md-0 mb-2">
            <b-form-group
              :label="$t('Start Date')"
            >
              <b-form-datepicker
                v-model="filterForm.dtStart"
                locale="en"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" class="mb-md-0 mb-2">
            <b-form-group
              :label="$t('End Date')"
            >
              <b-form-datepicker
                v-model="filterForm.dtEnd"
                locale="en"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body class="p-2">
      <DxDataGrid
        id="gridContainer"
        :height="600"
        :ref="dataGridRefName"
        :data-source="orderReportList"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :hover-state-enabled="true"
        key-expr="id"
        @rowDblClick="onClickItem"
      >
        <DxFilterRow :visible="true" :apply-filter="currentFilter" />
        <DxHeaderFilter :visible="true" />
        <DxPaging :enabled="false" />
        <DxSearchPanel :visible="true" :width="240" :placeholder="$t('Search...')" />
        <DxColumn :width="100" data-field="orderNo" caption="OrderNo" />
        <DxColumn
          :width="120"
          data-field="orderDate"
          caption="Date"
          alignment="right"
          data-type="date"
          sort-order="desc"
        />
        <DxColumn
          data-field="orderStatus"
          cell-template="completeStatusTemplate"
          caption="Durum"
        />
        <DxColumn data-field="user" caption="user" />
        <DxColumn data-field="productTitle" caption="Product" />
        <DxColumn data-field="paymentTypeLabel" caption="Payment" />
        <DxColumn data-field="ethnicName" caption="Ethnic" />
        <template #completeStatusTemplate="{ data }">
          <div>
            <b-badge v-show="data.value === 0"> {{ $t('None') }} </b-badge>
            <b-badge variant="danger" v-show="data.value === 1"> {{ $t('Pending') }}</b-badge>
            <b-badge variant="success" v-show="data.value === 2"> {{ $t('Processing') }}</b-badge>
            <b-badge variant="info" v-show="data.value === 3"> {{ $t('Completed') }}</b-badge>
            <b-badge variant="warning" v-show="data.value === 4"> {{ $t('Cancelled') }}</b-badge>
          </div>
        </template>
        <DxSummary>
          <DxTotalItem
            column="orderNo"
            summary-type="count"
          />
        </DxSummary>
      </DxDataGrid>
    </b-card>

    <SaveOrderResult v-if="modalObject.showModal" :modalObject="modalObject"/>
  </div>
</template>

<script>
import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'
import Ripple from 'vue-ripple-directive'

import { DxDataGrid, DxPaging, DxColumn, DxSearchPanel, DxHeaderFilter, DxFilterRow, DxSummary, DxTotalItem } from 'devextreme-vue/data-grid';
import { locale } from 'devextreme/localization';

import SaveOrderResult from '../../order-manage/g25-orders/detail/order-results/SaveOrderResult.vue';

export default {
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxHeaderFilter,
    DxFilterRow,
    DxSummary,
    DxTotalItem,
    SaveOrderResult,
  },
  directives: {
    Ripple,
  },
  
  data() {
    return {
      currentFilter: 'auto',
      dataGridRefName: 'dataGrid',
      filterForm: {
        dtStart: this.getDefaultDate(-30),
        dtEnd: this.getDefaultDate(),
      },
      orderReportList: [],

      modalObject: {
        item: {},
        showModal: false,
        onSuccess: this.fetchItem,
      },
    };
  },
  methods: {
    fetchOrderRepor() {
      ApiService.post('OrderResult/reports', this.filterForm, this)
        .then(response => {
          if (response.statusCode === 200) {
            response.result.forEach(value => {
              if (value.presetEthnicity) {
                value.ethnicName = value.presetEthnicity.ethnicName;
              } else {
                value.ethnicName = '';
              }
            });
            this.orderReportList = response.result;
          } else {
            AlertService.error(this, response.message)
          }
        });
    },

    onClickItem(data) {
      ApiService.get(`OrderResult/get/${data.key}`, {}, this)
        .then(response => {
          if (response.statusCode === 200) {
            Object.assign(this.modalObject.item, response.result);
            this.modalObject.showModal = true;
          } else {
            AlertService.error(this, response.message)
          }
        });
    },

    getDefaultDate(days) {
      const dtNow = new Date();
      if (days) {
        dtNow.setDate(dtNow.getDate() + days);
      }
      const dd = String(dtNow.getDate()).padStart(2, '0');
      const mm = String(dtNow.getMonth() + 1).padStart(2, '0');
      const yyyy = dtNow.getFullYear();

      return `${yyyy}-${mm}-${dd}`;
    },
  },
  mounted() {
    this.fetchOrderRepor();
  },
  created() {
    locale('en')
  },
};
</script>

<style scoped>
#gridContainer {
  height: 440px;
}

.options {
  padding: 20px;
  margin-top: 20px;
  background-color: rgba(191, 191, 191, 0.15);
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}

.option > span {
  margin-right: 10px;
}

.option > .dx-selectbox {
  display: inline-block;
  vertical-align: middle;
}
</style>
